import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h1", null, _toDisplayString(_ctx.game.statement), 1),
    (_ctx.game.statement === 'Game over.')
      ? (_openBlock(), _createElementBlock("p", _hoisted_1, " (Add more statements from the menu to play longer games) "))
      : _createCommentVNode("", true)
  ], 64))
}