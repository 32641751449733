import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, vShow as _vShow, withCtx as _withCtx, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-328a2066"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "button-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createVNode(_component_Button, {
      class: "yes-button",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.response = 'yes'))
    }, {
      default: _withCtx(() => [
        _createTextVNode("Yes")
      ]),
      _: 1
    }, 512), [
      [_vShow, _ctx.response !== 'yes']
    ]),
    _withDirectives(_createVNode(_component_Button, { class: "chosen yes-button" }, {
      default: _withCtx(() => [
        _createTextVNode("Yes")
      ]),
      _: 1
    }, 512), [
      [_vShow, _ctx.response === 'yes']
    ]),
    _withDirectives(_createVNode(_component_Button, {
      class: "no-button",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.response = 'no'))
    }, {
      default: _withCtx(() => [
        _createTextVNode("No")
      ]),
      _: 1
    }, 512), [
      [_vShow, _ctx.response !== 'no']
    ]),
    _withDirectives(_createVNode(_component_Button, { class: "chosen no-button" }, {
      default: _withCtx(() => [
        _createTextVNode("No")
      ]),
      _: 1
    }, 512), [
      [_vShow, _ctx.response === 'no']
    ]),
    _createVNode(_component_Button, {
      class: "submit-button",
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit(`postResponse`, _ctx.response)))
    }, {
      default: _withCtx(() => [
        _createTextVNode("Submit")
      ]),
      _: 1
    })
  ]))
}