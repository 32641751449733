
import { defineComponent } from "vue";
import Button from "primevue/button";

export default defineComponent({
  name: `Answer`,
  components: {
    Button,
  },
  props: [],
  data: () => ({
    response: ``,
  }),
  methods: {},
  mounted() {},
});
