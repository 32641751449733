
import { defineComponent } from "vue";
import Home from "./components/PageHome.vue";
import "primevue/resources/themes/vela-green/theme.css";

export default defineComponent({
  name: `App`,
  components: {
    Home,
  },
});
